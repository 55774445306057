import styled from "styled-components";
import breakpointSizes from "../../constants/breakpointSizes";
import { Modal } from "antd";

export const CustomModal = styled(Modal)`
  .ant-modal-content {
    padding: 0;
    border-radius: 8px;
    background: #fafafa;
    // width: 100%;
    margin: 116px 0;
  }
  .modalWrapper {
    padding: 25px;
    .headers {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    label {
      color: #171717;
      font-family: Bold;
    }
    .forms {
      margin-top: 25px;
      display: flex;
      // gap: 10px;
      justify-content: space-between;
      width: 100%;
      @media ${breakpointSizes.mobile} {
        display: block;
        width: 100%;
      }
    }
    .discount {
      p:first-child {
        font-family: Bold;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px;
        color: #000;
      }
      p {
        font-family: Light;
        margin: 5px 0 !important;
        font-size: 14px;
        color: #737373;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
    }
  }
  .btn {
    display: flex;
    justify-content: flex-end;
    // padding-bottom: 20px;
  }
  .cancel {
    cursor: pointer;
  }
`;

export const PreferenceContainer = styled.div`
    max-width: 1440px;
    margin: 0 auto;
}
  p {
    margin-bottom: 30px;
  }
  .custom-checkbox .ant-checkbox-inner {
    border-radius: 50%;
    padding: 8px;
  }
  width: 90%;
  margin: 0 auto;
  .errorMsg {
    margin-bottom: 25px;
  }
`;
export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  .header-left,
  .header-right {
    width: auto;
    height: 60px;

    .niyo-logo {
      height: 37px;
      margin-top: 10px;
    }
  }

  .header-link:nth-child(2) {
    margin: 0 30px;
  }

  .header-link {
    cursor: pointer;
  }

  @media ${breakpointSizes.mobile} {
    width: 100%;

    .header-center {
      display: none;
    }

    .header-right {
      display: none;
    }
  }

  @media ${breakpointSizes.desktop} {
  }
`;
export const InformationLayout = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  .informationWrapper {
    display: flex;
    justify-content: space-between;
    gap: 20px;

    img {
      width: 100%;
      // max-width: 100%;
      // border: 3px solid red;
    }
    .notify {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      margin-bottom: 40px;
      display: flex;
      align-items: center;
    }
  }
  .forms {
    padding-left: 100px;
    width: 60%;
    @media ${breakpointSizes.mobile} {
      padding: 0 30px;
      width: 100%;
    }
    img {
      width: 90px;
    }
  }
  .image {
    width: 40%;
    height: 100%;
    background: #fae4f5;
    .des {
      padding-top: 130px;
      width: 70%;
      color: var(--Niyo-Heading-Text, #1a1a1a);
      font-family: Light;
      font-size: 24px;
      font-style: italic;
      font-weight: 600;
      line-height: 140%;
      margin: 0 auto;
      p {
        text-align: right;
        color: var(--Niyo-Heading-Text, #1a1a1a);
        font-family: Light;
        font-size: 12px;
        font-style: italic;
        font-weight: 600;
        line-height: 140%;
      }
    }
    @media ${breakpointSizes.mobile} {
      display: none;
    }
  }
  .form-line {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 30px;
    margin-top: 30px;
    @media ${breakpointSizes.mobile} {
      display: block;
      width: 100%;
    }
    label {
      width: 100%;
    }
    .special-label {
      display: none !important;
    }
    .form-control {
      color: #000 !important;
      height: 60px !important;
      width: 100% !important;
      margin: 4% 0 2% 0 !important;
      border: 1px solid #e5e5e5 !important;
      color: #000 !important;
      padding: 0 0 0 2% !important;
      font-size: 16px !important;
      background-color: #fff !important;
      border-radius: 4px !important;
      ::placeholder {
        font-family: Light !important;
        color: #a0a6a6 !important;
        font-size: 14px !important;
      }
    }
    @media ${breakpointSizes.mobile} {
      flex-direction: column;
      flex-wrap: wrap;
      gap: 0;
      width: 100%;
    }
  }
  @media ${breakpointSizes.mobile} {
    padding: 0px 0 30px 0;
  }
`;
export const SelectField = styled.select`
  color: ${({ color }) => (color ? color : "#000")};
  height: 60px !important;
  width: ${({ width }) => (width ? width : "100%")};
  margin: 4% 0 2% 0;
  border: 1px solid #e5e5e5;
  color: #000;
  padding: 0 0 0 2%;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "16px")};
  background-color: #fff;
  border-radius: 4px;
  option {
    color: #a0a6a6;
    font-size: 14px;
  }
  ::placeholder {
    font-family: ${({ fontFamily }) => (fontFamily ? fontFamily : "Light")};
    color: #a0a6a6;
    font-size: 14px;
  }
  @media ${breakpointSizes.mobile} {
  }
`;

export const InputField = styled.input`
  color: ${({ color }) => (color ? color : "#000")};
  height: ${({ ht }) => (ht ? ht : "60px")};
  width: ${({ width }) => (width ? width : "100%")};
  margin: ${({ margin }) => (margin ? margin : "4% 0 2% 0")};
  border: 1px solid #e5e5e5;
  color: #000;
  padding: 0 0 0 2%;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "16px")};
  background-color: #fff;
  border-bottom-right-radius: 10px;
  border-radius: ${({ rd }) => (rd ? rd : "6px")};
  ::placeholder {
    font-family: ${({ fontFamily }) => (fontFamily ? fontFamily : "Light")};
    color: #a0a6a6;
    font-size: 14px;
  }
`;
export const BookingContainer = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  // padding: 30px 80px 30px 80px;
  width: 100%;
  .booking_section {
    max-width: 1440px;
    margin: 0 auto;
    padding: 30px 80px 30px 80px;
    @media (max-width: 509px) {
      padding: 0px;
    }
  }
  .progress_section {
    max-width: 1440px;
    margin: 0 auto;
    padding: 30px 80px 5px 80px;
    @media (max-width: 509px) {
      padding: 0px;
    }
  }
  .banners {
    @media (min-width: 450px) {
      display: none;
    }
    @media (min-width: 900px) {
      display: block;
    }
  }
  .tabs {
    width: 100%;
    height: 40px;
    color: #2d2b2b;
    font-family: Light;
    ul {
      display: flex;
      padding-left: 0px;
      li {
        padding-right: 1rem;
        cursor: pointer;
        list-style: none;
        transition: all 0.7s;
        span {
          background-color: #ff47ce;
          padding: 3px 8px;
          border-radius: 100%;
          color: #fff;
        }
      }
      li.active {
        span {
          background-color: transparent;
          border: 1px solid #ff47ce;
          color: #a4a6a7;
        }
      }
      @media (max-width: 768px) {
        width: 90%;
      }
    }
    @media (max-width: 769px) {
      padding: 2rem 0;
    }
  }

  .tab-content {
    h1 {
      font-size: 25px;
    }
  }

  @media ${breakpointSizes.mobile} {
    padding: 30px;
  }
`;

export const PickHairContainer = styled.div`
  .errorMsg {
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
    margin-bottom: 20px;
    // align-items: center;
  }
  .hair-style-container {
    display: flex;
    justify-content: space-between;
    padding: 30px 0 80px 0;
    .show-hairstyle {
      width: 28%;
      // padding-top: 20px;
      .show-hairstyle-frame {
        margin-top: 45px;
        width: 100%;
        border: 1px solid #ff47ce;
        height: auto;
        border-top-right-radius: 200px;
        border-top-left-radius: 200px;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        padding: 20px;
        .show-hair-picture {
          border-top-right-radius: 160px;
          border-top-left-radius: 160px;
          border-bottom-left-radius: 15px;
          border-bottom-right-radius: 15px;
          height: 350px;
          width: 100%;
          background-color: #ffc2ef;
          position: relative;
          .selected-image {
            // object-fit: cover;
            width: 100%;
            height: 100%;
            border-top-right-radius: 150px;
            border-top-left-radius: 150px;
            border-bottom-left-radius: 15px;
            border-bottom-right-radius: 15px;
            border: 1px solid #ff47ce;
          }
          .slay-tag {
            position: absolute;
            bottom: -35px;
            right: -30px;
            height: 100px;
            width: 100px;
          }
        }

        .show-hair-info {
          .hair-text-info {
            padding: 13px 0;
            display: flex;
            justify-content: space-between;

            h4 {
              font-size: 16px;
            }
          }
          .hair_extension {
            display: flex;
            border-radius: 24px;
            align-items: center;
            background: var(--Blue-50, #eff0ff);
            color: var(--Blue-500, #4353ff);
            text-align: center;
            // justify-content: center;
            text-align: center;
            margin-bottom: 13px;
            padding: 10px 20px;
            color: var(--Blue-500, #4353ff);
            text-align: center;
            // font-family: "Instrument Sans";
            .prices {
              font-size: 14px;
              font-style: normal;
              font-weight: 900;
              // line-height: 20px;
            }
          }
          .hair_extension > div {
            display: flex;
            gap: 6px;
          }
        }
      }
    }

    .hairstyle-list {
      .custom-select {
        padding: 20px !important;
        width: 100% !important;
        border: none !important;
        border-radius: 4px !important;
        background-color: red !important;
        color: white !important;
        font-size: 16px !important;
      }
      .hairstyle-content {
        @media (max-width: 470px) {
          max-width: 100%;
          padding-bottom: 15px;
          scrollbar-width: none;
          -ms-overflow-style: none;
        }
      }
      .categoryWrap {
        width: 50%;
        display: grid;
        grid-template-columns: repeat(7, 7fr);
        // flex-wrap: wrap;
        white-space: nowrap;
        padding-bottom: 20px;
        padding-top: 10px;

        @media ${breakpointSizes.tablet} {
          grid-template-columns: repeat(3, 5fr);
        }
        @media (max-width: 470px) {
          display: flex;
          width: 100%;
          overflow-x: scroll;
          flex-direction: row;
          gap: 15px;
          scrollbar-width: none;
          -ms-overflow-style: none;
          justify-content: space-between;

          &::-webkit-scrollbar {
            width: 0.5em;
          }
        }
        gap: 15px;
        .catItem {
          width: max-content;
          border-radius: 24px;
          cursor: pointer;
          padding: 8px 24px 8px 24px;
          border-style: solid;
          border-width: 1.5px;
          border-color: #1d1f25;
          transition: border-color 0.3s ease;
          @media (max-width: 470px) {
            display: inline-block;
            white-space: nowrap;
            margin-right: 10px;
            // flex: 0 0 auto;
            // white-space: nowrap !important;
            // border-radius: 24px !important;
            // cursor: pointer;
            // padding: 8px 24px;
            // border: 1.5px solid #1d1f25;
            // transition: border-color 0.3s ease;
          }
        }
        .catItem:hover {
          border-color: #ff47ce;
          color: #ff47ce !important;
        }
        .selected {
          border-style: solid;
          width: max-content;
          border-radius: 24px;
          cursor: pointer;
          padding: 8px 24px 8px 24px;
          border-width: 1.5px;
          border-color: #ff47ce;
          color: #ff47ce !important;
          transition: border-color 0.3s ease;
        }
      }
      select {
        border-radius: 4px;
        border: 1px solid var(--Niyo-Silver, #c8c8c8);
        background: #fff;
        padding: 6px 80px 6px 20px;
      }
      width: 65%;
      h1 {
        font-size: 25px;
      }
      .hair-list {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(4, 4fr);
        flex-wrap: wrap;
        @media ${breakpointSizes.mobile} {
          grid-template-columns: repeat(2, 4fr);
        }
        gap: 40px;
        .each-hair-container {
          box-sizing: border-box;
          .each-hair {
            width: 140px;
            height: 140px;
            border-radius: 100px;
            background-position: center;
            background-size: cover;
            background-color: #ff47ce;
            margin-top: 30px;
            cursor: pointer;
            img {
              object-fit: cover;
              background-position: top;
              // border: 3px solid #ff47ce;
              border: 1px solid #fc9e2f;
              width: 100%;
              height: 100%;
              border-radius: 100px;
            }
            .image-active {
              border: 6px solid #ff9270;
            }
          }
          h4 {
            font-size: 14px;
            margin-top: 10px;
            font-family: Light;
            width: 9rem;
          }
        }
      }
    }
  }
  @media ${breakpointSizes.mobile} {
    .hair-style-container {
      flex-direction: column;
      .show-hairstyle {
        width: 100%;
        .show-hairstyle-frame {
          // border-top-right-radius: 250px;
          // border-top-left-radius: 250px;
          padding: 20px;
          margin-bottom: 30px;
          .show-hair-picture {
            border-top-right-radius: 200px;
            border-top-left-radius: 200px;

            .selected-image {
              border-top-right-radius: 190px;
              border-top-left-radius: 190px;
            }
          }

          .show-hair-info {
            .hair-text-info {
              padding: 13px 0;
              display: flex;
              justify-content: space-between;
            }
            .hair_extension {
              display: flex;
              // justify-content: center;
              text-align: center;
            }
          }
        }
      }
      .hairstyle-list {
        width: 100%;
        h1 {
          margin-bottom: 0 !important;
        }
        .hair-list {
          .each-hair-container {
            height: auto;
            .each-hair {
              width: 130px;
              height: 130px;
            }
            h4 {
              line-height: 20px;
            }
          }
        }
      }
    }
  }
`;

export const ChoosePreference = styled.div``;

export const BookingSummary = styled.div`
  .btn {
    margin-top: 45px;
  }
  .booked-time {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    p {
      text-decoration: underline;
    }
  }
  .payment_data {
    margin-bottom: 10px;
    div {
      display: flex;
      justify-content: space-between;
    }
  }
  .services,
  .chosen-time,
  .options {
    border: 1px solid #e3e4ea;
    width: 100%;
    height: auto;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 30px;
    .service-head,
    .service-one,
    .total {
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;
      p {
        font-size: 14px;
        margin-bottom: 0;
      }
    }

    h4 {
      color: #484b4f;
      font-size: 15px;
    }
    .total {
      border-top: 1px solid #e3e4ea;
      margin-top: 30px;
      padding-top: 10px;
      p {
        font-family: Medium !important;
      }
    }
  }
  .payment-options {
    .payment-head {
      width: 100%;
      display: flex;
      justify-content: space-between;
      height: auto;
      h4,
      p {
        color: #484b4f;
        margin-bottom: 10px;
      }
    }
    .ant-select-selector {
      height: 40px;
      font-family: Light;
      color: #484b4f !important;
      outline: none !important;

      option {
        font-family: Light !important;
      }
      .ant-select-selection-search {
        .ant-select-selection-item {
          padding-top: 15px !important;
        }
      }
    }
    .ant-select-selector:hover {
    }
    .options {
      li {
        list-style: none;
        color: #484b4f;
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        transition: 0.2 ease-in;
      }
      li:hover {
        background-color: #e3e4ea;
        padding: 10px;
      }
    }
  }
  .policy {
    width: 100%;
    padding-top: 20px;
    margin-bottom: 40px;
    input {
      margin-top: -14px;
    }
    input:last-child {
      margin-top: 12px;
    }
    h4 {
      color: #484b4f;
    }
    .discounts {
      display: flex;
      max-height: 100%;
      align-items: center;
      div:first-child {
        width: 100%;
      }
      div:last-child {
        border: 1px solid #e5e5e5;
        padding: 22px 22px 28px 22px;
        // margin-top: 12px;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
      }
    }
    p {
      margin-top: 15px;
      color: #484b4f !important;
    }
    span {
      font-family: Light;
      font-size: 10px;
    }
  }
`;
